import request from '@/utils/request'
const ossUrl = process.env.VUE_APP_BASE_IMG
// 获取菜单
export const menuTree = data => {
  return request({
    url: '/SystemFunction/List',
    method: 'post',
    data,
    loading: true
  })
}

// 菜单新增、编辑
export const menuEdit = data => {
  return request({
    url: '/SystemFunction/' + (data.id > 0 ? 'Edit' : 'Add'),
    method: 'post',
    data,
    loading: true
  })
}
// 获取菜单详情
export const menuDetail = params => {
  return request({
    url: '/SystemFunction/GetById',
    method: 'get',
    params
  })
}
// 删除菜单
export const menuDel = params => {
  return request({
    url: '/SystemFunction/Delete',
    method: 'post',
    params
  })
}

// 配置
export const configureEdit = data => {
  return request({
    url: '/ProjectBasicsConfig' + (data.id > 0 ? '/Edit' : '/Add'),
    method: 'post',
    data,
    loading: true
  })
}

// 获取打印模板详情
export const printTemplateDetail = params => {
  return request({
    url: '/printTemplate/detail',
    method: 'get',
    params
  })
}

// 保存模板
export const printTemplateEdit = data => {
  return request({
    url: '/printTemplate/save',
    method: 'post',
    data,
    loading: true
  })
}

// 删除公告
export const noticeDel = params => {
  return request({
    url: '/SystemNotice/RemoveNotices',
    method: 'get',
    params
  })
}

// 新增、编辑公告
export const noticeEdit = data => {
  return request({
    url: '/SystemNotice' + (data.id > 0 ? '/EditNotices' : '/AddNotices'),
    method: 'post',
    data,
    loading: true
  })
}
// 公告详情
export const noticeDetail = params => {
  return request({
    url: '/SystemNotice/Details',
    method: 'get',
    params
  })
}

// 文件上传
export const uploadFile = data => {
  return request({
    url: ossUrl,
    method: 'post',
    data
  })
}

// 获取公司项目树
export const companyProjectTree = params => {
  return request({
    url: '/Project/GetCompanyProjectList',
    method: 'get',
    params
  })
}

// 批量操作权限
export const menuAction = data => {
  return request({
    url: '/SystemFunction/AllFunction',
    method: 'post',
    data,
    loading: true
  })
}

// 获取打印模板
export const printTmpList = params => {
  return request({
    url: '/PrintTemplate/GetPrintTemplateList',
    method: 'get',
    params
  })
}

// 公告微信推送
export const noticeWxPush = data => {
  return request({
    url: '/SystemNotice/SystemNoticePuth',
    method: 'post',
    data,
    loading: true
  })
}

// 打印模板设置默认模板
export const printDefaultTmp = data => {
  return request({
    url: '/PrintTemplate/setDefault',
    method: 'post',
    data
  })
}

// 打印模板删除
export const printTmpDel = data => {
  return request({
    url: '/PrintTemplate/delete',
    method: 'post',
    data
  })
}

// 获取公司树形结构
export const companyTree = data => {
  return request({
    url: '/SystemFunction/CompanyRoleList',
    method: 'post',
    data
  })
}

// 选择公司和角色并添加菜单
export const companyRolesMenu = data => {
  return request({
    url: '/SystemFunction/CompanyRoleFunction',
    method: 'post',
    data,
    loading: true
  })
}

// 公司角色菜单查看
export const companyRolesView = data => {
  return request({
    url: '/SystemFunction/CompanyRoleFunctionView',
    method: 'post',
    data
  })
}

// 删除角色
export const rolesDel = data => {
  return request({
    url: '/SystemRole/Remove',
    method: 'post',
    data
  })
}

// 新增/编辑角色
export const editRoles = data => {
  return request({
    url: '/SystemRole' + (data.id > 0 ? '/Edit' : '/Add'),
    method: 'post',
    data: data,
    loading: true
  })
}

// 权限菜单
export const authorizationTree = params => {
  return request({
    url: '/SystemRole/GetRoleFunctionTree',
    method: 'get',
    params: params,
    loading: true
  })
}

// 获取当前角色公司层级
export const rolesCompany = params => {
  return request({
    url: '/SystemRole/GetRoleCompanyTree',
    method: 'get',
    params
  })
}

// 获取公司下的角色，用于复制角色
export const companyRolesTree = params => {
  return request({
    url: '/SystemRole/GetCompanyRoles',
    method: 'get',
    params
  })
}

// 复制角色/权限
export const rolesCopy = data => {
  return request({
    url: '/SystemRole/UseOtherCompany',
    method: 'post',
    data
  })
}

// 员工删除
export const staffDel = data => {
  return request({
    url: '/SystemEmployee/Remove',
    method: 'post',
    data
  })
}

// 重置员工账户密码
export const resetPwd = data => {
  return request({
    url: '/SystemEmployee/ResetPassword',
    method: 'post',
    data
  })
}

// 登录账号强制下线
export const forcedOffline = data => {
  return request({
    url: '/SystemEmployee/ForceOffline',
    method: 'post',
    data
  })
}

// 新增/编辑员工账号
export const editStaff = data => {
  return request({
    url: '/SystemEmployee' + (data.id > 0 ? '/Edit' : '/Add'),
    method: 'post',
    data,
    loading: true
  })
}

// 获取选择用户的公司层级
export const staffCompanyTree = params => {
  return request({
    url: '/SystemEmployee/GetEmploeeCompanyTree',
    method: 'get',
    params
  })
}

// 获取当前用户在公司下拥有的角色和项目
export const getAuthorization = params => {
  return request({
    url: '/SystemEmployee/GetCompanyProjectsAndRoles',
    method: 'get',
    params
  })
}

// 用户授权
export const Authorization = data => {
  return request({
    url: '/SystemEmployee/Aouth',
    method: 'post',
    data
  })
}

// 角色详情
export const rolesDetail = params => {
  return request({
    url: '/SystemRole/QueryRoleDetails',
    method: 'get',
    params,
  })
}

// 获取公司树，可以通过名字，编号查询
export const projectTree = data => {
  return request({
    url: '/SystemFunction/CompanyProjectList',
    method: 'post',
    data
  })
}

// 获取运营角色菜单，新增传0
export const operateMenu = params => {
  return request({
    url: '/OperateRole/QueryRoleFunctions',
    method: 'get',
    params
  })
}

// 新增/编辑运营平台角色
export const adminRolesEdit = data => {
  return request({
    url: '/OperateRole/' + (data.id ? 'Edit' : 'Add'),
    method: 'post',
    data
  })
}

// 删除运营平台角色
export const adminRolesDel = data => {
  return request({
    url: '/OperateRole/Remove',
    method: 'post',
    data,
    loading: true
  })
}

// 修改运营平台用户状态
export const adminStaffUpdateStatus = data => {
  return request({
    url: '/SystemUser/ModifyStatus',
    method: 'post',
    data,
    loading: true
  })
}

// 新增/编辑运营平台员工
export const adminStaffEdit = data => {
  return request({
    url: '/SystemUser' + (data.id ? '/Edit' : '/Add'),
    method: 'post',
    data
  })
}

// 查询运营平台角色下拉选择
export const adminRolesDownList = data => {
  return request({
    url: '/OperateRole/QueryOperatorRoleDownList',
    method: 'post',
    data
  })
}

// 运营平台重置用户密码
export const adminStaffResetPwd = data => {
  return request({
    url: '/SystemUser/ResetPassWord',
    method: 'post',
    data,
    loading: true
  })
}

// 员工管理获取授权公司
export const authCompany = data => {
  return request({
    url: '/Company/GetDownListNoPermission',
    method: 'post',
    data
  })
}

// 查询会话的聊天记录
export const sessionMessage = params => {
  return request({
    url: '/ChatSession/QueryChatSessionMessages',
    method: 'get',
    params,
    loading: true
  })
}

// 获取平台人员
export const servicesList = data => {
  return request({
    url: '/SystemUser/DownList',
    method: 'post',
    data
  })
}

// 车场道闸删除队列
export const carGateDelQueue = data => {
  return request({
    url: '/ParkinglotRoadgate/DeleteCarRoadgateSyncQueue',
    method: 'post',
    data,
    loading: true
  })
}

// 车场道闸队列立即执行
export const carGateImplement = data => {
  return request({
    url: '/ParkinglotRoadgate/ExecuteNowCarRoadgateSyncQueue',
    method: 'post',
    data,
    loading: true
  })
}

// 预警管理获取配置邮箱
export const warningManageGetEmail = params => {
  return request({
    url: '/EarlyWarning/GetEmailConfig',
    method: 'get',
    params,
    loading: true
  })
}
// 预警管理配置发送邮箱
export const warningManageSetEmail = data => {
  return request({
    url: '/EarlyWarning/SetEmailConfig',
    method: 'post',
    data
  })
}

// 预警管理查询预警人员配置
export const warningManageGetPeople = data => {
  return request({
    url: '/EarlyWarning/QueryEarlyWarningPersonnelInfo',
    method: 'post',
    data,
    loading: true
  })
}

// 预警人员删除
export const warningManageDelPeople = data => {
  return request({
    url: '/EarlyWarning/Delete',
    method: 'post',
    data,
    loading: true
  })
}

// 预警人员新增
export const warningManageAddPeople = data => {
  return request({
    url: '/EarlyWarning/Add',
    method: 'post',
    data,
    loading: true
  })
}

// 公司配置，logo，标题
export const companyConfig = data => {
  return request({
    url: '/Company/SetLogicInfo',
    method: 'post',
    data
  })
}

// 获取小区坐标信息
export const projectMap = data => {
  return request({
    url: '/Project/QueryMapProjectList',
    method: 'post',
    data
  })
}

// 获取常用菜单
export const menuUse = data => {
  return request({
    url: '/SystemFunction/GetShortcutFunctions',
    method: 'post',
    data,
    loading: true
  })
}

// 保存常用菜单
export const menuUseSave = data => {
  return request({
    url: '/SystemFunction/SaveGetShortcutFunctions',
    method: 'post',
    data
  })
}